@import '@/styles/variables.scss';





























































































































































































































































































































































































































































































































































































































































.min-width-text {
  min-width: 160px;
}
.min-width-card {
  min-width: 500px;
}
.th {
  max-width: 280px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: block;
}
.hidden-input-container:hover {
  background-color: rgba(138, 141, 147, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
}
.hidden-input-container {
  background: #ffffff;
  border: 1px solid #2830ce;
  border-radius: 3px;
  height: 139px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
